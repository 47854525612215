import { createRouter, createWebHistory } from "vue-router";

//const commonTitle = "CHENOSYディズニーレストラン予約";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      redirect: "/QuoteMenu",
    },
    {
      name: "QuoteMenu",
      path: "/QuoteMenu",
      component: () => import(/* webpackChunkName: "QuoteMenu" */ "@/components/QuoteRequest/QuoteMenu"),
    },
    {
      name: "QuoteHotel",
      path: "/QuoteHotel",
      component: () => import(/* webpackChunkName: "QuoteHotel" */ "@/components/QuoteHotel/QuoteHotel"),
    },
    {
      name: "QuoteShowRestaurantMenu",
      path: "/QuoteShowRestaurantMenu",
      component: () => import(/* webpackChunkName: "QuoteShowRestaurantMenu" */ "@/components/QuoteShowRestaurant/QuoteShowRestaurantMenu"),
    },
    {
      name: "QuoteShowRestaurant",
      path: "/QuoteShowRestaurant",
      component: () => import(/* webpackChunkName: "QuoteShowRestaurant" */ "@/components/QuoteShowRestaurant/QuoteShowRestaurant"),
    },
    {
      name: "QuoteRestaurant",
      path: "/QuoteRestaurant/:service_type",
      component: () => import(/* webpackChunkName: "QuoteRestaurant" */ "@/components/QuoteRequest/QuoteRestaurant"),
    },
    {
      name: "Payment",
      path: "/payment/:uuid",
      component: () => import(/* webpackChunkName: "Payment" */ "@/components/Payment/AgentPayment"),
    },
    {
      name: "ReviewInput",
      path: "/review_input/:uuid",
      component: () => import(/* webpackChunkName: "Review" */ "@/components/Review/ReviewInput"),
    },
    {
      name: "ReviewList",
      path: "/review/:service_id",
      component: () => import(/* webpackChunkName: "ReviewList" */ "@/components/Review/ReviewList"),
    },
  ],
});

document.title = "レストラン予約代行 見積";

export default router;
