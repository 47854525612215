<template>
  <router-view />
</template>

<script>
// import repositoryFactory from "./api/repositoryFactory";
// const quoteRepository = repositoryFactory.get("quote");

// export default {
//   name: "App",
//   data() {
//     return {
//       data: "xxxxxxxx",
//     };
//   },
//   async created() {
//     try {
//       const response = await quoteRepository.restaurants();
//       this.data = response.data;
//     } catch (err) {
//       console.error("エラー発生：", err);
//     }
//   },
// };
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
