// console.debug = function(){/* NOP */};
// console.log = function(){/* NOP */};
// console.info = function(){/* NOP */};
// console.warn = function(){/* NOP */};
// console.error = function(){/* NOP */};

import { createApp } from "vue";
import App from "./App.vue";
import Amplify from "aws-amplify";
import aws_exports from "./aws-exports";
import "./index.css";
import router from "./router.js";

import liff from "@line/liff";

console.log("v1.0.0");

liff.init({
  liffId: process.env.VUE_APP_LIFF_ID,
});

aws_exports.API = {
  endpoints: [
    {
      name: "lineapi",
      endpoint: process.env.VUE_APP_API_ENDPOINT + "/lineapi",
    },
    {
      name: "payment",
      endpoint: process.env.VUE_APP_API_ENDPOINT + "/payment",
    },
  ],
};

Amplify.configure(aws_exports);

const app = createApp(App);
app.use(router);
app.mount("#app");
